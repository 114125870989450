/* Background */ .chroma { color: #f8f8f2; background-color: #272822 }
/* Error */ .chroma .ss4 { color: #960050; background-color: #1e0010 }
/* LineHighlight */ .chroma .hl { background-color: #ffffcc; display: block; width: 100% }
/* LineNumbers */ .chroma .ln { ; margin-right: 0.4em; padding: 0 0.4em 0 0.4em; }
/* Keyword */ .chroma .s3e8 { color: #66d9ef }
/* KeywordNamespace */ .chroma .s3eb { color: #f92672 }
/* Name */ .chroma .s7d0 {  }
/* NameAttribute */ .chroma .s7d1 { color: #a6e22e }
/* NameClass */ .chroma .s7d4 { color: #a6e22e }
/* NameConstant */ .chroma .s7d5 { color: #66d9ef }
/* NameDecorator */ .chroma .s7d6 { color: #a6e22e }
/* NameException */ .chroma .s7d8 { color: #a6e22e }
/* NameFunction */ .chroma .s7d9 { color: #a6e22e }
/* NameOther */ .chroma .s7df { color: #a6e22e }
/* NameTag */ .chroma .s7e2 { color: #f92672 }
/* Literal */ .chroma .sbb8 { color: #ae81ff }
/* LiteralDate */ .chroma .sbb9 { color: #e6db74 }
/* LiteralString */ .chroma .sc1c { color: #e6db74 }
/* LiteralStringEscape */ .chroma .sc25 { color: #ae81ff }
/* LiteralNumber */ .chroma .sc80 { color: #ae81ff }
/* Operator */ .chroma .sfa0 { color: #f92672 }
/* Punctuation */ .chroma .s1388 {  }
/* Comment */ .chroma .s1770 { color: #75715e }
/* GenericDeleted */ .chroma .s1b59 { color: #f92672 }
/* GenericEmph */ .chroma .s1b5a { font-style: italic }
/* GenericInserted */ .chroma .s1b5d { color: #a6e22e }
/* GenericStrong */ .chroma .s1b60 { font-weight: bold }
/* GenericSubheading */ .chroma .s1b61 { color: #75715e }
/* Text */ .chroma .s1f40 {  }

html {
  font-size: 16px;
  line-height: 1.618;
  font-family: var(--family-sans-serif);
}

body {
  color: var(--text);
  background: var(--background) var(--background-image) repeat fixed;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -moz-font-feature-settings: "liga" on;
  -webkit-tap-highlight-color: transparent;
  -webkit-overflow-scrolling: touch;
}

a {
  color: var(--text-strong);
  opacity: 0.7;
  text-decoration: none;

  &:focus,
  &:hover {
    color: var(--black-ter);
    opacity: 1;
    outline: none;
  }
}

.site-header {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  width: 20rem;
  padding: 1rem;
  box-sizing: border-box;
  text-align: center;

  & .is-active {
    & a {
      opacity: 1;
      color: var(--text-strong);
      font-weight: var(--weight-bold);
    }
  }

  & a {
    opacity: 0.5;
    color: var(--black-ter);

    &:focus,
    &:hover {
      opacity: 1;
      color: var(--text-strong);
    }
  }

  @media screen and (max-width: 800px) {
    width: 100%;
    position: relative;
  }
}

.main {
  position: relative;
  margin-left: 20rem;
  padding: 1rem 2rem;
  width: calc(100% - 20rem);
  max-width: 70rem;
  min-height: 100vh;
  box-sizing: border-box;
  border-left: 1px solid var(--main-border);
  border-right: 1px solid var(--main-border);
  box-shadow: 0 1px 4px var(--box-shadow);
  background: var(--main-background);

  & .list-footer {
    padding: 1.5rem 0;
  }

  @media screen and (max-width: 800px) {
    margin-left: 0;
    padding: 0 1rem;
    width: 100%;
    min-height: initial;
    border-left: none;
    border-right: none;
    border-top: 1px solid var(--main-border);
    border-bottom: 1px solid var(--main-border);
  }
}

.site-footer {
  display: none;

  @media screen and (max-width: 800px) {
    display: block;
    padding: 2rem 1rem;
    color: var(--text);
    font-size: var(--size-7);
    text-align: center;
    font-family: var(--family-monospace);

    & a {
      color: var(--text);
      opacity: 0.7;

      &:focus,
      &:hover {
        opacity: 1;
      }
    }
  }
}

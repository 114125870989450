.terms-list {
  & .tags-cloud {
    margin: 2em auto;

    & a {
      display: inline-block;
      position: relative;
      text-decoration: none;
      opacity: 1;
      border-radius: 5px;

      &.tag-s {
        color: var(--grey);
        font-size: 1rem;
        line-height: 2rem;
        padding: 0 0.5rem;
        opacity: 0.8;
      }

      &.tag-m {
        color: var(--grey-dark);
        font-size: 1.25rem;
        line-height: 2.5rem;
        padding: 0 0.75rem;
        opacity: 0.9;
      }

      &.tag-l {
        color: var(--grey-darker);
        font-size: 1.5rem;
        line-height: 3rem;
        padding: 0 1rem;
      }

      &.tag-xl {
        color: var(--black-ter);
        font-size: 1.75rem;
        line-height: 3.25rem;
        padding: 0 1.25rem;
      }

      &.tag-xxl {
        color: var(--primary);
        font-size: 2rem;
        line-height: 3.5rem;
        padding: 0 1.5rem;
      }

      &:hover {
        color: var(--white);
        background-color: var(--primary);
        opacity: 0.8;
      }
    }
  }
}

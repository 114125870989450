.post-entry {
  position: relative;
  line-height: 1.8;
  border-top: 1px solid transparent;

  & a {
    color: var(--text);

    &:focus,
    &:hover {
      color: var(--primary);
    }
  }

  & + .post-entry {
    border-color: var(--white-ter);
  }

  & .post-title {
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
    font-size: 1.3rem;
    line-height: 1.3;
    font-weight: var(--weight-bold);

    & a {
      opacity: 1;
    }
  }

  & .post-meta {
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    font-size: var(--size-7);
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  & .post-cover {
    position: absolute;
    top: 4.6rem;
    right: 0;
    width: 8rem;
    height: 8rem;
    object-fit: cover;
    font-family: 'object-fit: cover;'; // Polyfill for object-fit
    border-radius: var(--radius);

    & + .post-summary {
      padding-right: 9rem;
    }

    @media screen and (max-width: 800px) {
      position: static;
      width: 100vw;
      height: 56.25vw;
      border-radius: 0;
      margin: 0 -1rem;

      & + .post-summary {
        padding-right: 0;
      }
    }
  }

  & .post-summary {
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-size: 0.9rem;
    text-align: justify;
    opacity: 0.7;
  }

  & .post-footer {
    margin-top: 1rem;
    margin-bottom: 1.5rem;

    & .read-more {
      font-size: var(--size-7);
      letter-spacing: 1px;
      text-transform: uppercase;
    }
  }
}

/* asciinema shortcode */
.asciicast {
  margin: 2rem auto !important;
  text-align: center;

   @media screen and (max-width: 1170px) {
    & iframe {
      width: 100% !important;
    }
  }
}

/* jsfiddle shortcode */
.jsfiddle {
  margin: 2rem auto;
  width: 100%;
}

.cp_embed_wrapper {
  margin: 2rem auto;

  @media screen and (max-width: 800px) {
    & iframe {
      height: 265px;
    }
  }
}

/* shengxiang shortcode */
.shengxiang {
  display: block;
  margin: 2rem auto;
  width: 1054px;
  height: 768px;

  @media screen and (max-width: 1440px) {
    width: 100%;
    height: calc((100vw - 24rem) * 0.729);
  }

  @media screen and (max-width: 800px) {
    width: 100%;
    height: calc((100vw - 2rem) * 0.729);
  }
}

/* video.js shortcode */
.video-js {
  margin: 2rem auto;
  width: 100%;
  height: 0;
  padding-top: 56.25%;

  &.my-player-dimensions {
    margin: 2rem auto;
    width: 100%;
    height: 0;
    padding-top: 56.25%;
  }

  &:focus {
    outline: none;
  }

  & .vjs-control,
  & .vjs-big-play-button {
    &:focus {
      outline: none;
    }
  }

  & .vjs-poster {
    background-size: cover;
  }
}

/* music 163 */
.music163 {
  margin: 2rem auto;
  max-width: 100%;
}

/* gist shortcode */
.gist {
  margin: 2rem auto;

  & td,
  & th {
    border: none;
  }
}

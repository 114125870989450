.icon {
  font-family: 'iconfont' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-douban {
  &::before {
    content: var(--icon-douban);
  }
}

.icon-bilibili {
  &::before {
    content: var(--icon-bilibili);
  }
}

.icon-zhihu {
  &::before {
    content: var(--icon-zhihu);
  }
}

.icon-close {
  &::before {
    content: var(--icon-close);
  }
}

.icon-facebook {
  &::before {
    content: var(--icon-facebook);
  }
}

.icon-twitter {
  &::before {
    content: var(--icon-twitter);
  }
}

.icon-github {
  &::before {
    content: var(--icon-github);
  }
}

.icon-rss {
  &::before {
    content: var(--icon-rss);
  }
}

.icon-menu {
  &::before {
    content: var(--icon-menu);
  }
}

.icon-pinterest {
  &::before {
    content: var(--icon-pinterest);
  }
}

.icon-up {
  &::before {
    content: var(--icon-up);
  }
}

.icon-email {
  &::before {
    content: var(--icon-email);
  }
}

.icon-linkedin {
  &::before {
    content: var(--icon-linkedin);
  }
}

.icon-youtube {
  &::before {
    content: var(--icon-youtube);
  }
}

.icon-instagram {
  &::before {
    content: var(--icon-instagram);
  }
}

.icon-dribbble {
  &::before {
    content: var(--icon-dribbble);
  }
}

.icon-weibo {
  &::before {
    content: var(--icon-weibo);
  }
}

.icon-vimeo {
  &::before {
    content: var(--icon-vimeo);
  }
}

.icon-google {
  &::before {
    content: var(--icon-google);
  }
}

.icon-behance {
  &::before {
    content: var(--icon-behance);
  }
}

.icon-codepen {
  &::before {
    content: var(--icon-codepen);
  }
}

.icon-jsfiddle {
  &::before {
    content: var(--icon-jsfiddle);
  }
}

.icon-wechat {
  &::before {
    content: var(--icon-wechat);
  }
}

.icon-medium {
  &::before {
    content: var(--icon-medium);
  }
}

.icon-comment {
  &::before {
    content: var(--icon-comment);
  }
}

.icon-quora {
  &::before {
    content: var(--icon-quora);
  }
}
